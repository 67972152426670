import React, { useState } from "react";
import Input from "components/Input";
import Heading from "components/Heading";
import Button from "components/Button";
import { css } from "emotion";
import Select from "components/Select";
import withForm from "components/Form";
import {
  DOG_BREEDS,
  CAT_BREEDS,
  PETCO_DOG_BREEDS,
  PETCO_CAT_BREEDS,
} from "constants/values";
import moment from "moment";
import { notEmpty, validNumber } from "../utils/validate";

const MAX_YEARS = 50;
const MAX_MONTHS = 12;
const MAX_WEEKS = 52;

function convertIndexToName(i) {
  if (i === 1) {
    return "Second";
  } else if (i === 2) {
    return "Third";
  } else if (i === 3) {
    return "Fourth";
  } else if (i === 4) {
    return "Fifth";
  } else if (i === 5) {
    return "Sixth";
  } else {
    return "Other";
  }
}

const Patient = withForm(
  ({ updateForm, isFormValid, input, onNext, onChange, isPetco }) => {
    const [patients, setPatients] = useState(input.patients);
    const [patientAges, setPatientAges] = useState(
      Array(input.numberOfPets).fill({
        years: 0,
        months: 0,
        weeks: 0,
        valid: false,
      })
    );

    const updatePatient = (index, data) => {
      let newPatients = [...patients];
      newPatients[index] = Object.assign({}, patients[index], data);
      setPatients(newPatients);
      onChange({ patients: newPatients });
    };

    const updatePatientAge = (index, field, value) => {
      let { years, months, weeks } = patientAges[index];
      let validUpdate = false;

      if (field === "years" && validNumber(value, 0, MAX_YEARS)) {
        years = value;
        validUpdate = true;
      }
      if (field === "months" && validNumber(value, 0, MAX_MONTHS)) {
        months = value;
        validUpdate = true;
      }
      if (field === "weeks" && validNumber(value, 0, MAX_WEEKS)) {
        weeks = value;
        validUpdate = true;
      }

      let newPatientAges = [...patientAges];

      if (validUpdate) {
        const birthDate = moment()
          .subtract(years || 0, "years")
          .subtract(months || 0, "months")
          .subtract(weeks || 0, "weeks");

        if (birthDate.isBefore(moment())) {
          newPatientAges[index][field] = value;
          newPatientAges[index].valid = true;
          setPatientAges(newPatientAges);
          updatePatient(index, { birthDate: birthDate.format("YYYY-MM-DD") });
        }
      } else {
        newPatientAges[index].valid = false;
        setPatientAges(newPatientAges);
      }
    };

    const { givenName, numberOfPets } = input;

    return (
      <div>
        <Heading>
          Hi {givenName}, tell us about your pet
          {numberOfPets > 1 ? "s" : ""} so we can best help them.
        </Heading>
        <div
          className={css`
            margin-bottom: 30px;
          `}
        >
          {patients &&
            [...Array(numberOfPets).keys()].map((i) => (
              <div key={i}>
                <Input
                  placeholder={`Your ${
                    i > 0 ? `${convertIndexToName(i)} ` : ""
                  }Pet's Name`}
                  value={patients[i].name}
                  onChange={(v) => updatePatient(i, { name: v })}
                  validate={(v) => notEmpty(v)}
                  errorMessage={"Name required"}
                  onValidationChanged={(valid) => updateForm("name", valid)}
                />

                <div
                  className={css`
                    font-weight: 600;
                    margin-bottom: 7px;
                    margin-top: 20px;
                    padding-left: 1em;
                  `}
                >
                  Patient Details
                </div>

                <div
                  className={css`
                    margin: 0 1em;

                    > div:not(:last-child) {
                      margin-right: 15px;
                      margin-bottom: 10px;
                    }
                  `}
                >
                  <Select
                    onChange={(v) => updatePatient(i, { species: v })}
                    icon="pets"
                    placeholder="Species"
                    value={patients[i].species}
                    options={[
                      { value: "canine", label: "Dog" },
                      { value: "feline", label: "Cat" },
                    ]}
                  />

                  <Select
                    onChange={(v) => updatePatient(i, { breed: v })}
                    icon="category"
                    placeholder="Breed"
                    value={patients[i].breed}
                    options={
                      patients[i].species === "canine"
                        ? isPetco
                          ? PETCO_DOG_BREEDS
                          : DOG_BREEDS
                        : isPetco
                        ? PETCO_CAT_BREEDS
                        : CAT_BREEDS
                    }
                  />

                  <Select
                    onChange={(v) => updatePatient(i, { sex: v })}
                    icon="extension"
                    placeholder="Sex"
                    value={patients[i].sex}
                    options={[
                      {
                        value: "MALE",
                        label: "Male",
                      },
                      { value: "FEMALE", label: "Female" },
                    ]}
                  />

                  <Select
                    onChange={(v) => updatePatient(i, { neutered: v })}
                    icon="favorite"
                    value={patients[i].neutered}
                    placeholder="Neutered?"
                    options={[
                      {
                        value: "neutered",
                        label:
                          patients[i].sex === "MALE" ? "Neutered" : "Spayed",
                      },
                      { value: "intact", label: "Intact" },
                    ]}
                  />

                  <div
                    className={css`
                      font-weight: 600;
                      margin-bottom: 7px;
                      margin-top: 20px;
                    `}
                  >
                    Age
                  </div>

                  <div
                    className={css`
                      display: block;
                      margin-bottom: 10px;
                      > div {
                        display: inline-flex;
                        margin-right: 15px;

                        input {
                          width: 100%;
                          margin: 5px;
                        }
                      }
                    `}
                  >
                    <div>
                      <Input
                        type="number"
                        placeholder="Years"
                        min="0"
                        max="50"
                        value={
                          patientAges[i] ? patientAges[i].years.toString() : "0"
                        }
                        onChange={(v) => {
                          updatePatientAge(i, "years", parseInt(v, 10));
                        }}
                        validate={(v) => validNumber(parseInt(v), 0, MAX_YEARS)}
                        errorMessage="Valid years required"
                        onValidationChanged={(valid) =>
                          updateForm(`years-${i}`, valid)
                        }
                      />
                    </div>
                    <div>
                      <Input
                        type="number"
                        placeholder="Months"
                        value={
                          patientAges[i]
                            ? patientAges[i].months.toString()
                            : "0"
                        }
                        min="0"
                        max="12"
                        onChange={(v) =>
                          updatePatientAge(i, "months", parseInt(v, 10))
                        }
                        validate={(v) =>
                          validNumber(parseInt(v), 0, MAX_MONTHS)
                        }
                        errorMessage="Valid months required"
                        onValidationChanged={(valid) =>
                          updateForm(`months-${i}`, valid)
                        }
                      />
                    </div>
                    <div>
                      <Input
                        type="number"
                        placeholder="Weeks"
                        value={
                          patientAges[i] ? patientAges[i].weeks.toString() : "0"
                        }
                        min="0"
                        max="52"
                        onChange={(v) => {
                          updatePatientAge(i, "weeks", parseInt(v, 10));
                        }}
                        validate={(v) => validNumber(parseInt(v), 0, MAX_WEEKS)}
                        errorMessage="Valid weeks required"
                        onValidationChanged={(valid) =>
                          updateForm(`weeks-${i}`, valid)
                        }
                      />{" "}
                    </div>
                    {patientAges[i] && patientAges[i].valid && (
                      <div>
                        ~{" "}
                        {(patientAges[i].years ||
                          patientAges[i].months ||
                          patientAges[i].weeks) &&
                          moment()
                            .subtract(patientAges[i].years || 0, "years")
                            .subtract(patientAges[i].months || 0, "months")
                            .subtract(patientAges[i].weeks || 0, "weeks")
                            .format("MMMM YYYY")}
                      </div>
                    )}
                    {!patientAges[i].valid && "Enter a valid birthdate"}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Button
          fullWidth
          disabled={(() => {
            if (!isFormValid()) return true;

            for (let i = 0; i < numberOfPets; i++) {
              const patient = patients[i];
              if (!patient) return true;
              if (
                !patient.name ||
                !patient.breed ||
                !patient.sex ||
                !(
                  patientAges[i].years ||
                  patientAges[i].months ||
                  patientAges[i].weeks
                )
              )
                return true;
              return false;
            }
          })()}
          onClick={() => onNext()}
        >
          Next
        </Button>
      </div>
    );
  }
);

export default Patient;
