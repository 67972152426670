import React from "react";
import { css } from "emotion";

const Box = ({ children, borderColor, bgColor, color, onClick }) => (
  <div
    className={css`
      border: 1px solid ${borderColor ? borderColor : "rgba(0, 0, 0, 0.15)"};
      color: ${color ? color : "rgba(60, 60, 60, 1)"};
      background-color: ${bgColor ? bgColor : "transparent"};
      opacity: 0.8;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      margin-bottom: 15px;
      font-weight: 600;
      transition: opacity 0.2s ease;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    `}
    onClick={onClick}
  >
    {children}
  </div>
);

const Times = ({ selectedDate, times, onTimeSelect, onNotify }) => {
  const timesByProvider = times.reduce((rv, x) => {
    (rv[x.time] = rv[x.time] || []).push(x);
    return rv;
  }, {});

  const availableTimes = [];

  for (const t of Object.keys(timesByProvider)) {
    const pTimes = timesByProvider[t];
    const chosenTime = pTimes[Math.floor(Math.random() * pTimes.length)];
    availableTimes.push(chosenTime);
  }

  return (
    <div
      className={css`
        width: 100%;
        > div:not(:first-child) {
          margin-top: 12px;
        }
      `}
    >
      <div
        className={css`
          font-weight: 600;
          margin-bottom: 8px;
          font-size: 1.6rem;
        `}
      >
        Select an available time:
      </div>
      <div
        className={css`
          display: grid;
          width: 100%;
          grid-template-columns: repeat(auto-fill, 110px);
          grid-column-gap: 20px;
        `}
      >
        {availableTimes
          .slice()
          .sort((a, b) => a.time - b.time)
          .map((slot, idx) => (
            <Box
              key={idx}
              bgColor="rgb(35, 118, 229)"
              color="#FFF"
              onClick={() => onTimeSelect(slot)}
            >
              {slot.dateTime.format("h:mm a")}
            </Box>
          ))}
        {/* <Box
          borderColor="rgb(18, 173, 253)"
          color="rgb(18, 173, 253)"
          onClick={() => onNotify()}
        >
          <i
            className={cx(
              "material-icons",
              css`
                font-size: 0.9em;
              `
            )}
          >
            notifications
          </i>
          &nbsp;Notify Me
        </Box> */}
      </div>
    </div>
  );
};

export default Times;
