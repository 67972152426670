import React, { Component } from "react";
import Heading from "components/Heading";
import Button from "components/Button";
import { css } from "emotion";

const Patient = ({ name, species, breed, onSelect, isSelected, isNew }) => (
  <div
    className={css`
      background-color: ${isSelected ? "rgb(18, 173, 253)" : "#fff"};
      color: ${isSelected ? "#FFF" : "rgb(60, 60, 60)"};
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      opacity: ${isNew ? 0.7 : 1};

      &:hover {
        background-color: ${isSelected
          ? "rgb(18, 173, 253)"
          : "rgba(18, 173, 253, 0.1)"};
        cursor: pointer;
      }
    `}
    onClick={() => onSelect()}
  >
    <div
      className={css`
        margin-right: 15px;
        opacity: ${isSelected ? 1 : 0.4};
        line-height: 0;
        i {
          font-size: 2em;
        }
      `}
    >
      <i className="material-icons">check_circle_outline</i>
    </div>
    <div>
      <div
        className={css`
          font-weight: 700;
          font-size: 1.2em;
          margin: 0;
          line-height: 1;
        `}
      >
        {name}
      </div>
      {species && breed && (
        <div>
          {species}, {breed}
        </div>
      )}
    </div>
  </div>
);

class Client extends Component {
  state = { selectedPetIds: [] };
  render() {
    const { input, onNext, patients } = this.props;
    let { selectedPetIds } = this.state;

    return (
      <div>
        <Heading>
          Welcome back {input.givenName}! Which pet(s) of yours would you like
          to make the appointment(s) for?
        </Heading>
        <div
          className={css`
            margin-bottom: 30px;
          `}
        >
          {patients.map((p) => (
            <Patient
              key={p.id}
              {...p}
              isSelected={selectedPetIds && selectedPetIds.indexOf(p.id) !== -1}
              onSelect={() => {
                if (!selectedPetIds) selectedPetIds = [];
                if (!selectedPetIds || !Array.isArray(selectedPetIds))
                  selectedPetIds = [];
                const idx = selectedPetIds.indexOf(p.id);
                if (idx === -1) {
                  if (selectedPetIds.length < input.numberOfPets)
                    selectedPetIds.push(p.id);
                } else {
                  selectedPetIds.splice(idx, 1);
                }
                console.log(idx, selectedPetIds);
                this.setState({ selectedPetIds });
              }}
            />
          ))}
          <Patient
            key="new"
            name="New Patient"
            isSelected={selectedPetIds === null}
            onSelect={() => this.setState({ selectedPetIds: null })}
            isNew
          />
        </div>
        <Button
          fullWidth
          disabled={!input.patients || selectedPetIds?.length === 0}
          onClick={() => {
            onNext(this.state.selectedPetIds);
          }}
          disabledMessage="Please select or add a patient before proceeding."
        >
          Next
        </Button>
      </div>
    );
  }
}

export default Client;
