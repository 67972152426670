import React, { Component } from "react";
import moment from "moment-timezone";
import { css } from "emotion";
import { apiGet } from "../utils/api";
import Loading from "react-loading";
import Times from "components/Times";
import Select from "components/Select";
import DatePicker from "components/DatePicker";

const Load = () => (
  <div
    className={css`
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 100%;
    `}
  >
    <Loading type="bars" color="#afafaf" />
  </div>
);

const Day = ({ date, status, selected, onSelect, isToday, icon }) => {
  const color =
    status === "available"
      ? "rgb(18, 173, 253)"
      : status === "full"
      ? "rgb(238, 184, 104)"
      : "rgba(0, 0, 0, 0.2)";

  return (
    <div
      className={css`
        flex-grow: 1;
        flex-basis: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 60px;
      `}
    >
      <div
        className={css`
          font-weight: 300;
        `}
      >
        {isToday ? (
          <span
            className={css`
              font-size: 1rem;
              background-color: rgb(41, 165, 115);
              color: #fff;
              font-weight: 400;
              border-radius: 5px;
              padding: 0.2em 0.6em 3px;
              margin-top: 3px;
              margin-bottom: 3px;
              display: block;
            `}
          >
            Today
          </span>
        ) : date ? (
          date.format("dd")
        ) : (
          "..."
        )}
      </div>
      <div
        onClick={() => onSelect()}
        className={css`
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin-top: 5px;
          font-size: 1.7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${color};
          transition: background-color 0.2s ease, color 0.2s ease;

          ${status === "available" && {
            fontWeight: "700",
          }}

          ${status === "closed"
            ? {
                textDecoration: "line-through",
              }
            : {
                border: `1px solid ${color}`,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: color,
                  color: "#FFF",
                },
              }}

          ${selected && {
            backgroundColor: color,
            color: "#FFF",
          }}
        `}
      >
        {icon ? <i className="material-icons">{icon}</i> : date.format("D")}
      </div>
    </div>
  );
};

class DateTimeSelect extends Component {
  constructor(props) {
    super(props);

    this.today =
      props.openDate && moment(props.openDate, "YYYY-MM-DD").isAfter(moment())
        ? moment(props.openDate, "YYYY-MM-DD").tz(props.timezone).startOf("day")
        : moment().tz(props.timezone).startOf("day");

    this.state = {
      selectedDate: props.selectedDatetime
        ? moment(props.selectedDatetime).tz(props.timezone).startOf("day")
        : moment(this.today),
      startDate: moment(this.today),
      endDate: moment(this.today).add(4, "days").endOf("day"),
    };
  }

  componentDidMount() {
    this.getAvailableDates();
    this.getAvailableTimes(this.state.selectedDate);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.numberOfPets !== this.props.numberOfPets ||
      prevProps.locationId !== this.props.locationId ||
      prevProps.appointmentTypeId !== this.props.appointmentTypeId ||
      prevProps.input.coat !== this.props.input.coat ||
      prevProps.input.species !== this.props.input.species ||
      prevProps.input.groomingService !== this.props.input.groomingService ||
      prevProps.input.weightRange !== this.props.input.weightRange
    ) {
      this.setState({ timesLoading: true });
      this.getAvailableTimes(this.state.selectedDate);
    }
  }

  getAvailableDates() {
    apiGet(
      `book_appointment/date_statuses?locationId=${
        this.props.locationId
      }&startDate=${this.state.startDate.format(
        "YYYY-MM-DD"
      )}&endDate=${this.state.endDate.format("YYYY-MM-DD")}&number=${
        this.props.numberOfPets
      }&appointmentTypeId=${this.props.appointmentTypeId}&coat=${
        this.props.input.coat
      }&`
    ).then(({ data }) => {
      this.setState({ dateStatuses: data.dates });
    });
  }

  getAvailableTimes(date) {
    const { locationId, numberOfPets, appointmentTypeId, input } = this.props;
    apiGet(
      `book_appointment/available_times?locationId=${locationId}&date=${date.format(
        "YYYY-MM-DD"
      )}&number=${numberOfPets}&appointmentTypeId=${appointmentTypeId}&coat=${
        input.coat
      }&species=${input.species}&groomingService=${
        input.groomingService
      }&weightRange=${input.weightRange}`
    ).then(({ data }) => {
      this.setState({ availableTimes: this.convertSlotsToTimes(data.times, date), timesLoading: false });
    });
  }

  convertSlotsToTimes(times, date) {
    return times.map((providerSlot) => {
      return {
        ...providerSlot,
        dateTime: moment(date).add(providerSlot.time, "minutes")
      }
    })
  }

  changeDateSelected(date) {
    this.setState({ selectedDate: date, timesLoading: true });
    this.getAvailableTimes(date);
  }

  openDatePicker() {
    this.setState({ openDatePicker: true });
  }

  setNewDay(date) {
    const { timezone } = this.props;
    const newDate = moment(date);
    this.changeDateSelected(
      moment()
        .tz(timezone)
        .set({
          year: newDate.get("year"),
          month: newDate.get("month"),
          date: newDate.get("date"),
        })
        .startOf("day")
    );
  }

  render() {
    const {
      dateStatuses,
      selectedDate,
      timesLoading,
      availableTimes,
      openDatePicker,
    } = this.state;

    if (!dateStatuses) return <Load />;

    return this.props.sameDayOnlyAppointments ||
      this.props.orgSlug === "petmedic" ? (
      <div>
        <div
          className={css`
            font-weight: 600;
            margin-bottom: 10px;
          `}
        >
          What time would you like your appointment?
        </div>

        <div
          className={css`
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-weight: 700;
          `}
        >
          <i
            className="material-icons"
            style={{ fontSize: "1.8rem", marginRight: "7px" }}
          >
            {"event"}
          </i>

          {selectedDate.format("ddd., MMM D")}
        </div>

        <div
          className={css`
            background-color: #fef9c3;
            display: flex;
            flex-direction: row;
            font-weight: 600;
            font-size: 1.6rem;
            margin-bottom: 8px;
            padding: 12px 0;
          `}
        >
          <i
            className="material-icons"
            style={{
              fontSize: "1.8rem",
              marginRight: "7px",
              color: "#a16207",
              paddingLeft: "5px",
            }}
          >
            {"warning"}
          </i>
          <div
            className={css`
              color: #a16207;
            `}
          >
            Please note we only accept same
            {this.props.orgSlug === "petmedic" ? " or next" : ""} day
            appointments
          </div>
        </div>

        {this.props.orgSlug === "petmedic" ? (
          <div
            className={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {[0, 1].map((d) => {
              const date = moment(this.today).add(d, "days");

              return (
                <Day
                  key={date}
                  date={date}
                  status="available"
                  selected={date.isSame(selectedDate, "day")}
                  onSelect={() => this.changeDateSelected(date)}
                  isToday={date.isSame(moment(), "day")}
                />
              );
            })}
          </div>
        ) : null}

        {timesLoading ? (
          <Load />
        ) : (
          <LoadedTimes
            availableTimes={availableTimes}
            selectedDate={selectedDate}
            onDateTimeSelect={this.props.onDateTimeSelect}
            footer={this.props.footer}
          />
        )}
      </div>
    ) : (
      <div>
        <div
          className={css`
            font-weight: 600;
            margin-bottom: 10px;
          `}
        >
          When would you like your appointment?
        </div>
        <div
          className={css`
            > div {
              margin-right: 15px;
              margin-bottom: 15px;
            }
          `}
        >
          <Select
            open={openDatePicker}
            icon="event"
            value={selectedDate.format("ddd., MMM D")}
            onClick={() =>
              this.setState({ openDatePicker: !this.state.openDatePicker })
            }
          />
          {this.props.appointmentTypeId !== 389 && (
            <Select
              onChange={(v) => {
                this.props.onNumberOfPetsChange(parseInt(v, 10));
              }}
              icon="pets"
              value={this.props.numberOfPets}
              options={[
                { value: 1, label: "1 pet" },
                { value: 2, label: "2 pets" },
                { value: 3, label: "3 pets" },
                { value: 4, label: "4 pets" },
                { value: 5, label: "5 pets" },
                { value: 6, label: "6 pets" },
              ]}
            />
          )}
        </div>
        {openDatePicker ? (
          <DatePicker
            onDayClick={(date) => {
              this.setNewDay(date);
              this.setState({ openDatePicker: false });
            }}
            selectedDays={
              new Date(
                selectedDate.year(),
                selectedDate.month(),
                selectedDate.date()
              )
            }
          />
        ) : (
          <div
            className={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {[0, 1, 2, 3, 4].map((d) => {
              const date = moment(this.today).add(d, "days");

              return (
                <Day
                  key={date}
                  date={date}
                  status={dateStatuses[date.format("YYYY-MM-DD")]}
                  selected={date.isSame(selectedDate, "day")}
                  onSelect={() => this.changeDateSelected(date)}
                  isToday={date.isSame(moment(), "day")}
                />
              );
            })}

            <Day
              key="more"
              status="available"
              icon="event"
              onSelect={() => this.openDatePicker()}
            />
          </div>
        )}
        <div
          className={css`
            margin-top: 30px;
          `}
        >
          {timesLoading ? (
            <Load />
          ) : (
            <LoadedTimes
              availableTimes={availableTimes}
              selectedDate={selectedDate}
              onDateTimeSelect={this.props.onDateTimeSelect}
              footer={this.props.footer}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DateTimeSelect;

const LoadedTimes = ({
  availableTimes,
  selectedDate,
  onDateTimeSelect,
  footer,
}) => {
  return availableTimes && availableTimes.length > 0 ? (
    <Times
      key={selectedDate.format("YYYY-MM-DD")}
      selectedDate={moment(selectedDate)}
      times={availableTimes}
      onTimeSelect={(slot) =>
        onDateTimeSelect({
          datetime: slot.dateTime.toISOString(),
          providerId: slot.provider_id,
        })
      }
    />
  ) : (
    <div
      className={css`
        color: rgba(60, 60, 60, 0.8);
      `}
    >
      <span dangerouslySetInnerHTML={{ __html: footer }} />
    </div>
  );
};
